<template>

  <div>

    <b-card

      title="Edit configuration"
    >

      <b-form @submit.prevent>
        <b-row>

          <b-col cols="6">
            <b-form-group
              label="BigQuery DataSet"
              label-for="v-bqDataSet"
            >
              <b-form-input
                id="v-bqDataSet"
                v-model="settings.bqDataset"
                placeholder="BigQuery DataSet"
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="BigQuery Table"
              label-for="v-bgTable"
            >
              <b-form-input
                id="v-bqTable"
                v-model="settings.bqTable"
                placeholder="BigQuery Table"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">

            <b-form-group
              label="Run at(UTC time)"
              label-for="v-runAt"
            >
              <b-form-input
                id="v-runAt"
                v-model="settings.runAt"
                placeholder="Run at"
                required
              />
            </b-form-group>

          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="updateSettings"
            >
              Save settings
            </b-button>

          </b-col>

        </b-row>
      </b-form>

    </b-card>

    <b-card-code
      no-body
      title="Competitors Asins"
    >

      <b-overlay
        :show="loading"
        rounded="sm"
      >

        <b-table
          striped
          hover
          :items="competitorsAsins"
          :fields="fields"
        >

          <template #cell(id)="data">
            <span v-b-tooltip.hover.top="data.item.id">
              #
            </span>

          </template>

          <template #cell(createdAt)="data">
            {{ data.item.createdAt|formatDateTime }}
          </template>

          <template #cell(actions)="data">
            <!-- <router-link :to="{ name: 'edit-asins', params: { configurationId: data.item.id }}">
                    <b-button

                      variant="primary"
                      size="sm"
                      class="btn-tour-finish mr-1"
                    >
                    Edit
                    </b-button>

                  </router-link> -->

            <a
              class="text-danger"
              @click="remove(data.item.id)"
            >Remove</a>
          </template>

        </b-table>

        <div
          v-if="rows > 25"
          class="mt-1 d-flex justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            hide-goto-end-buttons
            :total-rows="rows"
            :per-page="perPage"
            @change="handlePageChange"
          />
        </div>

      </b-overlay>

    </b-card-code>

  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BCard, BForm, BCol, BRow, BFormGroup, BFormInput, BButton, VBTooltip, BPagination, BSpinner, BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    BSpinner,
    ToastificationContent,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      settings: {},
      competitorsAsins: [],
      fields: ['id', 'asin', 'label', 'createdAt', 'actions'],
      currentPage: 1,
      perPage: 25,
      lastPage: 0,
      rows: 0,
    }
  },

  created() {
    this.loadAsins()
    this.loadSettings()
  },

  methods: {

    handlePageChange(value) {
      this.page = value

      this.loadAsins(this.page)
    },

    loadAsins(page = 1) {
      this.loading = true

      this.$store.dispatch('competitorAsin/fetchAll', page)
        .then(result => {
          this.competitorsAsins = result.data.docs
          this.currentPage = result.data.page
          this.rows = result.data.totalDocs
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
        }).finally(() => {
          this.loading = false
        })
    },

    remove(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger ',
          cancelButton: 'btn btn-outline-primary  ml-1',
        },
        buttonsStyling: false,

      }).then(result => {
        if (result.value === true) {
          this.loading = true

          this.$store.dispatch('competitorAsin/remove', id).then(
            () => {
              this.loadAsins()
            },
            error => {
              console.log(err)
            },
          ).finally(() => {
            this.loading = false
          })
        }
      },
      error => {
        console.log(err)
      })
    },

    loadSettings() {
      this.$store.dispatch('appSetting/getByKey', 'search_terms_report')
        .then(result => {
          this.settings = result.data
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading settings', 'danger')
        })
    },
    updateSettings() {
      const params = {
        value: this.settings,
        key: 'search_terms_report',
      }

      this.$store.dispatch('appSetting/updateByKey', params)
        .then(() => {
          this.showToast('Success', 'Item was updated successfully', 'success')
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while updating item', 'danger')
        })
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
